import SocialMediaIcons from "../components/SocialMediaIcons";

const Footer = () => {
  return (
    <footer className="h-48 bg-red pt-10">
      <div className="w-10/12 mx-auto">
        <div>
          <div className="flex flex-row gap-2">
          <img alt="logo" className="w-28 h-18" src="assets/logo.png"></img>
          <SocialMediaIcons></SocialMediaIcons>
          </div>

          <p className="font-playfair text-md text-yellow">
          
            ©2022 webezy. All Rights Reserved.
          </p>
          <p className="text-xxs">
          Images by <a href="https://www.freepik.com">Freepik</a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
